import * as React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import {getImage} from "gatsby-plugin-image"
import {BgImage} from "gbimage-bridge"

import * as style from "./404.module.css"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Icon from "../assets/images/img404.svg"
import {multilingualLink} from "../utils/multilaguagesLink";
import {LocaleProvider} from "../hooks/useLocale";
import useTranslation from "../hooks/useTranslation";
import {useLocalStorage} from "../hooks/useLocalStorage";

const NotFoundPage0 = ({pageContext: {locale}}) => {
  const {bgrImage} = useStaticQuery(
    graphql`
        query {
            bgrImage: file(relativePath: { eq: "404bcg.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1440
                        formats: [AVIF, AUTO, WEBP]
                        webpOptions: { quality: 100 }
                    )
                }
            }
        }
    `
  )

  const bcg = getImage(bgrImage)
  const translations = useTranslation();
  const [storedLang] = useLocalStorage()

  return (
    <>
      <Seo title="404: Not found" translations={translations} locale={locale}/>
      <BgImage image={bcg}>
        <Layout locale={storedLang} translations={translations}>
          <div>
            <div className={style.notFound}>
              <h3 className={`${style.header} b`}>{translations.page404Heading1}</h3>
              <Icon alt="not found" className={style.notFoundIcon}/>
              <p className={`${style.comforting} b label-text`}>
                {translations.page404Heading2}
              </p>
              <Link to={multilingualLink('', storedLang)}
                    className={`${style.redirectButton} primary-button`}
              >
                {translations.takeMeBack}
              </Link>
            </div>
          </div>
        </Layout>
      </BgImage>
    </>
  )
}

const NotFoundPage = ({children, pageContext}) => (
  <LocaleProvider>
    <NotFoundPage0 pageContext={pageContext}>{children}</NotFoundPage0>
  </LocaleProvider>
)
export default NotFoundPage